code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  height: 100%;
  user-select: none;
  font-family: "Cute Font", sans-serif !important;
}

.loginComp .ant-card .ant-card-body {
  padding: 0 !important;
}

.login {
  position: relative;
  overflow: hidden;
}

.loginFormArea {
  margin-top: 20vh;
}

@media screen and (max-width: 990px) {
  .loginFormArea {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
}

.carouselImg {
  width: 100%;
  height: 100vh;
}

.spaceLoginArea {
  margin-top: 5vh;
}

@media screen and (max-width: 500px) {
  .carouselImg {
    width: 100%;
    height: 50vh;
  }

  .spaceLoginArea {
    margin-top: 1vh;
  }
}

.login .ant-card-body {
  padding: 0 !important;
}

.login .ant-col {
  padding: 0 !important;
}

.login-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  opacity: 0.7;
}

.login-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
}

.login h1 {
  margin-top: 50px;
  font-weight: 600;
  color: black;
  font-family: "Rubik", sans-serif;
}

.login h2 {
  font-family: "Rubik", sans-serif;
}

.ant-input {
  font-family: "Rubik", sans-serif !important;
}

button {
  font-family: "Rubik", sans-serif !important;
}

.headerRight {
  font-size: 14px;
  margin-right: -30px !important;
}

.profileIcon {
  font-size: 20px;
  margin-top: 3px;
}

.profileUserName {
  font-size: 14px;
}

.profileSchoolName {
  margin-top: 5px;
  font-size: 12px;
  color: gray;
  font-weight: 600;
}

.para {
  font-family: "Sniglet", sans-serif;
  font-size: 13px;
  text-align: left;
}

.bs {
  font-family: "Bobby Jones Condensed", sans-serif;
  color: white;
  display: inline-block;
  position: absolute;
  left: 50px;
  margin-left: -8px;
}

.forzaCardGif {
  width: 170px;
  height: 170px;
  margin-top: -37px;
  margin-left: -20px;
}

@media screen and (max-width: 550px) {
  .headerRight {
    font-size: 8px !important;
  }

  .profileIcon {
    font-size: 12px;
    margin-left: 5px;
    margin-right: -10px;
  }

  .profileUserName {
    font-size: 10px;
  }

  .profileSchoolName {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) {
  .para {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 580px) {
  .para {
    font-size: 9px !important;
  }

  .loginFormArea {
    height: 90vh;
  }

  .loginH1 {
    margin-top: 10vh;
  }

  .loginH2 {
    margin-top: 10vh;
    margin-bottom: -20vh;
  }

  .spaceLoginArea {
    margin-top: 5vh;
  }

  .loginBtn {
    font-size: 8px;
  }
}

@media screen and (max-width: 450px) {
  .para {
    font-size: 7px !important;
  }

  .bs {
    bottom: -10px;
    font-size: 8px;
  }

  .forzaCardGif {
    width: 100px;
  }
}

@media screen and (max-width: 405px) {
  .headerRight {
    font-size: 6px !important;
    margin-right: -40px !important;
  }

  .forzaCardGif {
    width: 80px;
  }
}

@media screen and (max-width: 330px) {
  .headerRight {
    font-size: 5px !important;
    margin-right: -40px !important;
  }
}

.forzaCardImg {
  width: 30px;
  height: 30px;
  margin-right: 3px;
  position: absolute;
  top: 23px;
  margin-left: -5px;
  margin-top: 3px;
}

.ant-menu {
  font-weight: 500 !important;
  font-family: "Rubik", sans-serif !important;
}

.ant-card {
  font-family: "Rubik", sans-serif !important;
}

.ant-card-body {
  font-family: "Rubik", sans-serif !important;
}

.ant-col {
  font-family: "Rubik", sans-serif !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb--20 {
  margin-bottom: -20px;
}

.w-100 {
  width: 100%;
}

.closeBtnModal {
  background-color: red;
  color: white;
}

.saveBtnModal {
  background-color: green;
  color: white;
}

.borderNone {
  border: none;
}

.green {
  color: green;
}

.red {
  color: red;
}

.bold-total {
  font-weight: bold;
}

.text-muted {
  color: #909090 !important;
  font-size: 13px !important;
}

.custom-popconfirm {
  width: 300px;
}

.custom-steps-container {
  overflow-x: auto; /* Yatay kaydırmayı etkinleştir */
  white-space: nowrap; /* Adımların yan yana gelmesini sağla */
}

.ant-steps-item-title {
  font-size: 0.7rem !important;
}

.custom-steps .ant-steps-item {
  min-width: 70px; /* veya istediğiniz bir genişlik */
  display: inline-block;
}

.homeworkcomponent .ant-card {
  margin-top: 20px;
  padding: 10px 0 !important;
}

.homeworkcomponent .ant-card .ant-card-body {
  padding: 5px !important;
}

.userInfo .ant-card {
  padding: 9px 0 !important;
}

.userInfo .ant-card .ant-card-body {
  padding: 7px !important;
}

.ant-menu-item-selected {
  background-color: #ea5b0c !important;
  color: white !important;
}

.ant-menu-submenu-selected:not(.ant-menu-submenu-title)
  .ant-menu-submenu-title-selected {
  background-color: #ea5b0c !important;
  opacity: 0.9 !important;
  color: white !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-menu-light,
:where(.css-dev-only-do-not-override-qgg3xn).ant-menu-light > .ant-menu {
  color: black !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  background-color: #ea5b0c !important;
  color: white !important;
}

:where(.css-qgg3xn).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  background-color: #ea5b0c !important;
  color: white !important;
}

.gradientBackground {
  background-image: url("../media/forzaCardBg.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
}

.ant-card {
  border-radius: 20px !important;
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.moving-svg {
  animation: moveUpDown 3s infinite;
}

#studentCondition .ant-card-body {
  padding: 10px;
}

.ant-layout .ant-layout-sider-trigger {
  background-color: #ffffff !important;
  color: orange !important;
  font-size: 20px;
  box-shadow: none !important;
}

.generalHome .ant-card-body {
  padding: 5px !important;
}

.homeworkPage .ant-card {
  padding: 10px 5px !important;
}

.ant-alert-info {
  background: linear-gradient(to right, #fff, #fff5df);
  border: 1px solid gray;
}

.ant-alert-message {
  color: black !important;
  font-size: 20px !important;
  font-weight: 600;
}

.ant-alert-description {
  color: red !important;
  font-weight: 500;
}

.ant-timeline-item {
  padding-bottom: 3px !important;
}

.paymentsCard p {
  margin: 7px 0 !important;
  font-weight: 700;
}

.header {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 1000;
  font-weight: 500;
}

.header a {
  color: black !important;
}

.header a:hover {
  color: #ea5b0c !important;
}

.ant-table-thead > tr > th {
  padding: 5px !important;
  text-align: center !important;
}

.flipCard {
  width: 100%;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 21px;
}

.book {
  background-color: #fff;
  width: 450px;
  height: 250px;
  border-radius: 20px;
  perspective: 600px;
  position: relative;
}

.book::before {
  content: "";
  position: absolute;
  z-index: 5;
  width: 50%;
  height: 100%;
  right: 50%;
  background-color: #e0e0e0;
  border-radius: 20px 0 0 20px;
  transform-origin: center right;
  transform: rotateY(180deg);
  transition: 0.7s ease all;
}

.book:hover::before {
  transform: rotateY(0deg);
}

.book .pg {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.book .pg.pg1 {
  background-color: white;
  border-radius: 20px 0 0 20px;
  left: 0;
}

.book .pg.pg2 {
  background-color: white;
  right: 0;
  border-radius: 0 20px 20px 0;
}

.book.page1::before {
  right: 50%;
  transform: rotateY(180deg);
}

.book.page2::before {
  right: 50%;
  transform: rotateY(0deg);
}

.book p {
  position: relative;
  display: inline-block;
  padding: 0 10px !important;
  font-size: 14px !important;
}

.profileCard .ant-card-body {
  padding: 5 !important;
  border-radius: 10px;
}

.profileCard .ant-card {
  line-height: 0 !important;
}

.btn {
  border: 1px solid orange;
  padding: 3px 20px;
  border-radius: 10px;
}

.btn:hover {
  background-color: orange !important;
  color: white !important;
  border: none !important;
}

.navigateBtn {
  background-color: inherit;
  color: orange;
  border: none;
}

.navigateBtn:hover {
  background-color: inherit !important;
  color: orange !important;
  border: none !important;
}

.ant-layout-header {
  line-height: 5px !important;
}

.loginBtn {
  letter-spacing: 2px !important;
  background-color: #ff9933 !important;
  color: white !important;
  transition: background-color 0.3s ease;
}

.loginBtn:hover {
  opacity: 0.9 !important;
  border: none;
  cursor: pointer !important;
  font-weight: 600;
}

.timeTableComp .ant-select-selector {
  font-size: 10px !important;
}

.timeTableComp
  :where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  padding: 10px !important;
}

:where(.css-qgg3xn).ant-table-wrapper .ant-table-cell,
:where(.css-qgg3xn).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-qgg3xn).ant-table-wrapper .ant-table-tbody > tr > th,
:where(.css-qgg3xn).ant-table-wrapper .ant-table-tbody > tr > td,
:where(.css-qgg3xn).ant-table-wrapper tfoot > tr > th,
:where(.css-qgg3xn).ant-table-wrapper tfoot > tr > td {
  padding: 10px !important;
}

.w-200 {
  width: 200px;
}

.assignmentCardComponent
  :where(.css-dev-only-do-not-override-qgg3xn).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 200px;
}

.ant-progress-text {
  display: none !important;
}

.ant-progress-inner {
  width: 100% !important;
}

.ant-progress-line {
  margin-inline-start: -20px !important;
}

.boxShadowNone {
  box-shadow: none !important;
}

.efsoIcon {
  width: 50px;
  height: 50px;
  margin: 10px -5px 0 -30px;
}

.efsoIconCollapsed {
  width: 50px;
  height: 50px;
  margin-left: -25px !important;
}

.rollCallCardComp
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card-body {
  padding: 0 !important;
}

.paragrafPiliIcon {
  width: 30px;
  margin-left: -8px;
  margin-right: -8px;
  height: 25px;
}

.paragrafPiliIconCollapsed {
  width: 30px;
  margin-left: -5px !important;
  height: 25px;
}

@font-face {
  font-family: "Bobby Jones Condensed";
  src: url("../fonts/BobbyJonesCondensed-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Sniglet";
  src: url("../fonts/Sniglet-Regular.ttf") format("truetype");
}

.profileCardComp {
  height: 150px;
  color: white;
  background-image: url("../media/forzaCardBg.png");
  background-size: cover;
  background-position: center;
}

.pieCard :where(.css-dev-only-do-not-override-qgg3xn).ant-card .ant-card-body {
  padding: 0 !important;
}

.genderCardComp
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card-body {
  padding: 0 !important;
}

.chart-container {
  position: relative;
}

.chart-overlay {
  width: 30%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.chart:hover .apexcharts-tooltip {
  z-index: 2;
}

.timelineDashboard p {
  line-height: 3px !important;
}

.timelineDashboard
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card-body {
  padding: 10px !important;
}

.homePageTimeTable
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card-body {
  padding: 10px !important;
  border-radius: 0 !important;
}

.homePageTimeTable
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card {
  border-radius: 3px 10px 10px 3px !important;
  margin: 5px 0;
}

.teacherHomePage
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card-body {
  padding: 10px !important;
  border-radius: 0 !important;
}

.teacherHomePage
  :where(.css-dev-only-do-not-override-qgg3xn).ant-timeline
  .ant-timeline-item {
  font-size: 12px;
}

.studentRecordsPage
  .ant-select-show-search:where(
    .css-dev-only-do-not-override-qgg3xn
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  border-radius: 20px;
}

.homeworkCardComp
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card-head,
.homeworkCardComp
  :where(.css-dev-only-do-not-override-qgg3xn).ant-card
  .ant-card-body {
  padding: 5px 10px !important;
}

.homeworkComp
  .ant-pagination-item
  a:not(.homeworkComp .ant-pagination-item-active a) {
  border: 1px solid gray !important;
  border-radius: 15px !important;
  z-index: 10 !important;
}

.homeworkComp .ant-pagination-item:hover {
  background-color: inherit !important;
}

.homeworkComp .ant-pagination-item-active a {
  background-color: #7365fe !important;
  color: white !important;
  border-radius: 15px !important;
  margin: 3px 2px 0 2px !important;
}

.homeworkComp
  :where(.css-dev-only-do-not-override-qgg3xn).ant-pagination
  .ant-pagination-item-active {
  border: none !important;
}

.homeworkComp .ant-pagination-item {
  margin: 0px !important;
}

.homeworkComp .ant-pagination-prev a,
.homeworkComp .ant-pagination-next a {
  margin-top: 100px !important;
  color: #7365fe !important;
}

.homeworkComp .ant-pagination-next {
  margin: 5px 0 0 3px !important;
}

.homeworkComp .ant-pagination-prev {
  margin: 5px 3px 0 0 !important;
}

.ant-empty-description {
  display: none !important;
}

.apexcharts-toolbar {
  display: none !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background-color: #FAFAFB;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px !important;
}

.selectedDayInTable {
  background-color:#7364fe;
  padding:10px 25px;
  border-radius:10px;
  color:white;
}

.ant-progress-bg {
  height: 3px !important;
}

.pdf-container {
  display: flex;
  justify-content: center;
  user-select: none;
}

.wrap {
  margin: auto 5rem;
}

.wrap .ant-card-body {
  padding: 0px !important;
}

.controls {
  margin: 20px;
  justify-content: center;
}

.controls button {
  padding: 1rem 2rem;
  background-color: black;
  color: white;
  border-radius: 5px;
  margin-right: 2rem;
  outline: none;
  border: none;
  box-sizing: 1px 2px 5px black;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab, :where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-card.ant-tabs-top >div>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 8px 8px 0 0;
  border: none;
  background-color: inherit;
  color: #7365FF !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab-active, :where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-card.ant-tabs-top >div>.ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #ffffff;
  color: #7365FF !important;
  border-bottom: 2px solid #7365FF;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #7365FF !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-top >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-top >div>.ant-tabs-nav::before {
  display: none !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #7365FF;
  background-color: #7365FF !important;
}
